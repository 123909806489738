<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-08 03:50:54
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-01 15:02:47
-->
 
<template>
  <el-card class="box data" v-loading="loading">
    <el-table
      :data="tableData.data"
      @sort-change="onSort"
      border
      style="width: 100%"
    >
      <el-table-column prop="level" label="级别" width="100"> </el-table-column>
      <el-table-column label="消息" width="300">
        <template #default="scope">
          <el-link
            type="primary"
            :underline="false"
            size="small"
            @click="onDtl(scope.row)"
            >{{ scope.row.message }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        label="机器"
        prop="machine"
        width="180"
      ></el-table-column>
      <el-table-column
        label="应用"
        prop="application"
        width="180"
      ></el-table-column>
      <el-table-column
        label="来源"
        prop="callsite"
        min-width="300"
      ></el-table-column>
      <!-- <el-table-column label="错误详情" prop="exception" min-width="300"></el-table-column> -->
      <el-table-column prop="logged" label="时间" width="200">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    >
    </el-pagination>
  </el-card>

  <el-dialog
    title="详情"
    v-model="visibleDialog"
    width="800px"
    top="10vh"
    :close-on-click-modal="false"
  >
  <div style="white-space: pre-wrap;line-height:24px;">
    {{current.exception}}
  </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      visibleDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //详情
      current: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.post("admin/v1/nLog", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 查看详情
     * @param {*} item
     */
    onDtl(item) {
      this.current = item;
      this.visibleDialog=true;
    },
  },
};
</script>

<style  scoped>
</style>